@import "./Variables.scss";
@import "./Mixins.scss";

.publicSiteLayout {
	html, body {
		width: 100%;
		height: 100%;
		color: $text-primary;
	}

	div {
		box-sizing: border-box;
	}

	div,
	span,
	p,
	button,
	h3,
	h2,
	a {
		font-family: "Mulish", sans-serif !important;
		color: inherit;
		font-size: 1.2rem;
		line-height: 1.8rem;
		font-weight: 500;
	}

	.title {
		font-family: "Lora", serif !important;
	}

	.small-text {
		font-size: 16px;
		line-height: 24px;
	}

	.MuiSvgIcon-root path {
		stroke: unset;
	}

	.clear-btn {
		background: transparent;
		color: inherit;
		border: none;
		padding: 0;
		font: inherit;
		cursor: pointer;
		outline: 0;
	}

	.layout-wrapper {
		height: 100vh;
	}

	.layout-wrapper .navbar {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
	}

	.layout-body {
		display: flex;
		flex-direction: column;
		height: auto;
		width: 100%;
	}

	.layout-footer {
		position: relative;
		clear: both;
		margin-top: 24px;
	}

	.site-body {
		display: flex;
		flex-direction: column;
		gap: $side-padding;
		padding: 80px 0;

		@include mobile {
			gap: 80px;
			padding: 40px 0;
		}
	}

	.chart-list {
		display: flex;
		flex-direction: column;
		gap: $side-padding;

		@include mobile {
			gap: 80px;
		}
	}

	.site-section,
	.layout-footer .footer {
		@include add-side-padding;
	}

	.site-section-divider {
		border-top: 1px solid $gray-2;
	}

	.site-header-title {
		font-size: 4rem;
		line-height: normal;
		font-weight: 500;

		.highlight {
			color: $brand-blue;
			display: inline-block;
		}

		@include mobile {
			font-size: 2rem;
		}
	}

	.site-header {
		display: flex;
		flex-direction: column;
		gap: 2.1rem;
		flex-basis: 50%;
		flex-shrink: 0;

		.site-header-body {
			font-size: 1.5rem;
			line-height: 2.1rem;
			font-weight: 600;
			display: flex;
			gap: 2.1rem;
			align-items: center;
			flex-wrap: wrap;
		}

		@include mobile {
			gap: 1rem;

			.site-header-body {
				font-size: 1.2rem;
				line-height: 1.8rem;
				display: flex;
				flex-direction: column;
				gap: 1rem;
				align-items: flex-start;
			}
		}
	}

	.site-body {
		.site-section-wrapper,
		.site-section,
		.site-section-body {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 48px;

			@include mobile {
				gap: 24px;
			}
		}

		.site-section-body-group,
		.site-section-body-group p,
		.site-section-body-group a,
		.column-group-item,
		.site-section-body {
			font-size: 1.5rem;
			line-height: 2.1rem;

			@include mobile {
				font-size: 1.2rem;
				line-height: 1.8rem;
			}
		}

		.site-section-body-group p {
			margin: 0;
		}

		.site-section-body-group.column-group {
			display: flex;
			flex-direction: row;
			gap: 48px;
			flex-wrap: wrap;

			.column-group-item {
				flex-basis: calc(50% - 24px);
			}

			@include small-mobile {
				.column-group-item {
					flex-basis: 100%;
				}
			}
		}
	}

	.public-site-dialog {
		.MuiPaper-root {
			border-radius: 24px;
		}

		.MuiDialogTitle-root {
			padding: 24px 24px 16px;
		}

		.MuiDialogContent-root {
			padding: 0 24px;
		}

		.MuiDialogActions-root {
			padding: 24px;
		}

		.MuiButton-root {
			text-transform: unset;
		}

		.MuiButton-root,
		.MuiButton-label {
			font-size: 16px;
			line-height: normal;
			font-weight: 700;
		}

		h2 {
			font-weight: 700;
		}

		.data-table,
		p {
			font-size: 14px;
			line-height: 20px;
		}

		p {
			margin: 16px 0;
		}

		.dialog-text {
			font-weight: 400 !important;
		}
	}

	.site-section-body-group a {
		color: $brand-blue;
	}

	.site-body .site-section.site-section-row {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		gap: 48px;
	}
}


.nationalViewBtn {
	font-size: 0.7em !important;
	line-height: 1em !important;
	margin-right: 20px;
}