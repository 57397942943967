@import "../../../Pages/PublicSite/Variables.scss";
@import "../../../Pages/PublicSite/Mixins.scss";

.newsletter-subscribe-modal {
    .MuiDialog-paper.MuiDialog-paperScrollPaper {
        border-radius: 12px;
        height: auto !important;
    }

    .MuiDialogTitle-root,
    .MuiDialogContent-root {
        padding: 24px;
        font-weight: 700 !important;
    }

    .modal-subscribe-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .form-input-group {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-between;
        }

        .MuiFormControl-root {
            flex-basis: calc(50% - 12px);

            @include small-mobile {
                flex-basis: 100%;
            }
        }

        .MuiFormLabel-root:not(.Mui-error) {
            color: $brand-black;
            opacity: 0.5;

            &.Mui-focused {
                color: $brand-black;
                opacity: 0.5;
            }
        }

        .text-input {
            color: $brand-black;

            &:not(.Mui-error) {
                fieldset {
                    border-color: $brand-black;
                }

                &:hover {
                    .fieldset:not(.Mui-error) {
                        border-color: $brand-black;
                    }
                }

                &.Mui-focused fieldset {
                    border-color: $brand-black !important;
                }
            }
        }

        .modal-btn {
            @include apply-button-styles;
        }
    }

    .modal-description {
        margin-bottom: 24px;
    }

    .MuiOutlinedInput-notchedOutline legend {
        span {
            font-size: 11px;
        }
    }

    .MuiFormHelperText-root {
        margin-left: 3px;
    }

    .MuiFormControlLabel-label.Mui-disabled {
        span, a {
            color: $brand-black;
            font-size: 14px;
        }
    }

    .MuiDialogActions-root {
        padding: 24px;
        .button-link {
            color: $brand-blue;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        }
    }

    .clear-btn {
		background: transparent;
		color: inherit;
		border: none;
		padding: 0;
		font: inherit;
		cursor: pointer;
		outline: 0;
	}
}